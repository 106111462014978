import { TextField, styled } from '@mui/material'

export const ScheduleDemoFooterTextfield = styled(TextField)({
  '& label': {
    color: '#FFF',
  },
  '& label.Mui-focused': {
    color: '#FFF',
  },
  '& .MuiInputBase-root': {
    '&::before': {
      borderBottom: '1px solid #FFF',
    },
    '&::after': {
      borderBottom: '1px solid #FFF',
    },
    // '&:hover': {
    //   borderBottom: '1px solid #FFF',
    // },
    '& fieldset': {
      borderColor: '#FFF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFF',
    },
  },
})

export const ContactUsTextfield = styled(TextField)({
  '& label': {
    color: '#000',
  },
  '& label.Mui-focused': {
    color: '#000',
  },
  '& .MuiInputBase-root': {
    '&::before': {
      borderBottom: '1px solid #000',
    },
    '&::after': {
      borderBottom: '1px solid #000',
    },
    // '&:hover': {
    //   borderBottom: '1px solid #000',
    // },
    '& fieldset': {
      borderColor: '#000',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#000',
    },
  },
})
