import { createSvgIcon } from '@mui/material'

export const LocationIcon = createSvgIcon(
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 9.75C8.48333 9.75 8.896 9.57933 9.238 9.238C9.57933 8.896 9.75 8.48333 9.75 8C9.75 7.51667 9.57933 7.104 9.238 6.762C8.896 6.42067 8.48333 6.25 8 6.25C7.51667 6.25 7.104 6.42067 6.762 6.762C6.42067 7.104 6.25 7.51667 6.25 8C6.25 8.48333 6.42067 8.896 6.762 9.238C7.104 9.57933 7.51667 9.75 8 9.75ZM8 19.8C5.4 17.5333 3.45833 15.4333 2.175 13.5C0.891667 11.5667 0.25 9.8 0.25 8.2C0.25 5.78333 1.02933 3.854 2.588 2.412C4.146 0.970667 5.95 0.25 8 0.25C10.05 0.25 11.8543 0.970667 13.413 2.412C14.971 3.854 15.75 5.78333 15.75 8.2C15.75 9.8 15.1083 11.5667 13.825 13.5C12.5417 15.4333 10.6 17.5333 8 19.8Z"
      fill="white"
    />
  </svg>,
  'LocationIcon',
)
