import React, { useState, useEffect } from 'react';
import cheerio from 'cheerio';
import axios from 'axios';

//Next import
import Image from 'next/image';
import { useRouter } from 'next/router';

//MUI Import
import { Box, ListItem, Grid, Typography, ListItemText, Link } from '@mui/material';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

//UI import
import { ParagraphText, HeadSectionImg } from '../ui';
import FooterContactDetails from './footer/contact-detail';
import MeditaggLogo from '../../public/logo/meditagg_logo.svg';

const sitemap = ['About', 'Features', 'Contact', 'Testimonial', 'FAQs'];
const socialMedia = ['Facebook', 'Instagram', 'Linkedin', 'Twitter', 'Youtube'];

const Footer = () => {
  const router = useRouter();
  const [recentPost, setRecentPost] = useState([]);

  const redirectBlogPage = (e) => {
    router.push(`/blog/${e.slug}`);
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://blogs.meditagg.com//wp-json/wp/v2/posts');
        if (response.data.length > 0) {
          const sortedPosts = response.data.sort((a, b) => new Date(b.modified) - new Date(a.modified));
          setRecentPost(sortedPosts.slice(0, 2));
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  const convertImg = (e) => {
    const contentHtml = e.content.rendered;
    const $ = cheerio.load(contentHtml);
    const figureElement = $('figure');
    if (figureElement.length > 0) {
      const imgElement = figureElement.find('img');
      if (imgElement.length > 0) {
        let figureHtml = imgElement.attr('src');
        return `${figureHtml}?w=200&h=200`;
      } else {
        console.log("Image element not found within the figure element");
      }
    } else {
      console.log("Figure element not found");
    }
  };

  return (
    <>
      <Grid container spacing={2} style={{ backgroundColor: "#1F2B45", color: "white", padding: "12px" }}>
        {/* Logo and Content */}
        <Grid item xs={12} md={4}>
          <Image src={MeditaggLogo} alt="Meditagg" style={{ cursor: 'pointer' }} priority />
          <Typography variant="h6" fontSize='15px' fontWeight='500' marginTop="20px">
            Meditagg offers innovative solutions for healthcare management, ensuring efficiency and reliability in patient Health care.
          </Typography>
        </Grid>

        {/* Sitemap */}
        <Grid item xs={12} md={2}>
          <Typography variant="h6" marginTop="20px">Sitemap</Typography>
          {sitemap.map((data, index) => (
            <ListItem disablePadding key={index}>
              <ListItemText
                primary={
                  <ParagraphText variant="subtitle2" sx={{ color: '#FFF', fontSize: '15px', fontWeight: '500', marginBottom: '10px' }}>
                    {data}
                  </ParagraphText>
                }
                sx={{ margin: 0 }}
              />
            </ListItem>
          ))}
        </Grid>

        {/* Social Media */}
        <Grid item xs={12} md={2}>
          <Typography variant="h6" marginTop="20px">Social Media</Typography>
          {socialMedia.map((data, index) => (
            <ListItem disablePadding key={index}>
              <ListItemText
                primary={
                  <ParagraphText variant="subtitle2" sx={{ color: '#FFF', fontSize: '15px', fontWeight: '500', marginBottom: '10px' }}>
                    {data}
                  </ParagraphText>
                }
                sx={{ margin: 0 }}
              />
            </ListItem>
          ))}
        </Grid>

        {/* Head Office */}
        <Grid item xs={12} md={2}>
          <Typography variant="h6" marginTop="20px">Head Office</Typography>
          <FooterContactDetails />
        </Grid>

        {/* Recent Blogs */}
        <Grid item xs={12} md={2}>
          <Typography variant="h6" marginTop="20px">Recent Blogs</Typography>
          {recentPost.map((data, index) => (
            <Box onClick={() => { redirectBlogPage(data) }} sx={{ display: 'flex', alignItems: 'center', margin: '20px', cursor: 'pointer' }}>
              <Box sx={{ flex: 1 }}>
                <HeadSectionImg src={convertImg(data)} width={100} height={100} style={{ height: '50px', objectFit: 'cover' }} />
              </Box>
              <Box sx={{ flex: 2, padding: "0px 0px 0px 10px" }}>
                <Typography variant="h6" style={{ fontFamily: 'Poppins', fontSize: "12px", color: 'white' }}>{data.title.rendered}</Typography>
              </Box>
            </Box>
          ))}
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Box>
            <a href="https://www.facebook.com/meditaggEMR/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
              <FacebookOutlinedIcon style={{ marginRight: '10px' }} />
            </a>
            <a href="https://www.instagram.com/meditaggemr/?hl=en" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
              <InstagramIcon style={{ marginRight: '10px' }} />
            </a>
            <a href="https://www.linkedin.com/company/meditagg/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
              <LinkedInIcon style={{ marginRight: '10px' }} />
            </a>
            <a href="https://x.com/meditagg" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
              <TwitterIcon style={{ marginRight: '10px' }} />
            </a>
            <a href="https://www.youtube.com/@meditagg-emrsoftware8591" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
              <YouTubeIcon />
            </a>
          </Box>
        </Grid>

      </Grid>
    </>
  )
}

export default Footer;
