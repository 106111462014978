import React from 'react'

// Nextjs
import Image from 'next/image'

// MUI
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import PhoneIcon from '@mui/icons-material/Phone';

// Icons
import { LocationIcon } from '../../icons/footer/location'
import { EmailIcon } from '../../icons/footer/mail'

// UI
import { NextLink, ParagraphText } from '../../ui'

// Meditagg Logo
import MeditaggLogo from '../../../public/logo/meditagg_logo.svg'

const FooterContactDetails = () => {
  return (
    <Box style={{marginTop: "-30px"}}>
      <List sx={{ mt: 1.5 }}>
        <ListItem disablePadding>
          <ListItemButton sx={{ p: '8px 0', '&:hover': { background: 'transparent', cursor: 'text' } }}>
            <ListItemIcon>
              <LocationIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <ParagraphText variant="subtitle2" sx={{ color: '#FFF', fontSize: '15px', fontWeight: '500' }}>
                  Address
                </ParagraphText>
              }
              sx={{ margin: 0 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              p: '8px 0',
              '&:hover': {
                background: 'transparent',
                cursor: 'text',
              },
            }}
          >
            <ListItemIcon>
              <PhoneIcon fontSize="small" sx={{ color: '#FFF' }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <NextLink href="https://api.whatsapp.com/send?phone=919740205986">
                  <ParagraphText variant="subtitle2" sx={{ color: '#FFF', fontSize: '15px' }}>
                    +91 97402 05986
                  </ParagraphText>
                </NextLink>
              }
              sx={{ margin: 0 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              p: '8px 0',
              '&:hover': {
                background: 'transparent',
                cursor: 'text',
              },
            }}
          >
            <ListItemIcon>
              <EmailIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <NextLink href="mailto:contact@meditagg.com">
                  <ParagraphText variant="subtitle2" sx={{ color: '#FFF', fontSize: '15px' }}>
                    contact@meditagg.com
                  </ParagraphText>
                </NextLink>
              }
              sx={{ margin: 0 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              p: '8px 0',
              '&:hover': {
                background: 'transparent',
                cursor: 'text',
              },
            }}
          >
            <ListItemIcon>
              <EmailIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <NextLink href="mailto:admin@meditagg.com">
                  <ParagraphText variant="subtitle2" sx={{ color: '#FFF', fontSize: '15px' }}>
                    admin@meditagg.com
                  </ParagraphText>
                </NextLink>
              }
              sx={{ margin: 0 }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )
}

export default FooterContactDetails
