// Nextjs
import Link from 'next/link'

// MUI
import { styled } from '@mui/material'

export const NextLink = styled(Link)({
  textDecoration: 'none',
  color: '#000',
})
