import { createSvgIcon } from '@mui/material'

export const EmailIcon = createSvgIcon(
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.3 15.5C1.8 15.5 1.375 15.325 1.025 14.975C0.675 14.625 0.5 14.2 0.5 13.7V2.3C0.5 1.8 0.675 1.375 1.025 1.025C1.375 0.675 1.8 0.5 2.3 0.5H17.7C18.2 0.5 18.625 0.675 18.975 1.025C19.325 1.375 19.5 1.8 19.5 2.3V13.7C19.5 14.2 19.325 14.625 18.975 14.975C18.625 15.325 18.2 15.5 17.7 15.5H2.3ZM10 8.55L18 3.45L17.85 2L10 7L2.15 2L2 3.45L10 8.55Z"
      fill="white"
    />
  </svg>,
  'EmailIcon',
)
