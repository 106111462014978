import { Box, styled } from '@mui/material'

export const FeaturesIconBox = styled(Box)(({ theme }) => ({
  width: theme.typography.pxToRem(54),
  height: theme.typography.pxToRem(54),
  background: '#FFF',
  border: `${theme.typography.pxToRem(1)} solid var(--primary-color)`,
  borderRadius: theme.typography.pxToRem(10),
  display: 'grid',
  placeItems: 'center',
}))
