import { styled, Typography } from '@mui/material'

export const SectionHeadingText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontFamily: 'var(--primary-font)',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(56),
    lineHeight: '150%',
    letterSpacing: '0.03em',
  },
  [theme.breakpoints.down('sm')]: {
    fontFamily: 'var(--primary-font)',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(40),
    lineHeight: '150%',
    letterSpacing: '0.03em',
  },
}))

export const SecondaryHeadingText = styled(Typography)(({ theme }) => ({
  fontFamily: 'var(--primary-font)',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(20),
  lineHeight: '150%',
  letterSpacing: '0.03em',
  color: '#13140F',
}))

export const ParagraphText = styled(Typography)(({ theme }) => ({
  fontFamily: 'var(--primary-font)',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: theme.typography.pxToRem(17),
  lineHeight: '200%',
  letterSpacing: '0.03em',
}))
