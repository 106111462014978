import React from 'react'

// Navbar
import Navbar from './navbar'

// Footer
import Footer from './footer'

import StickyIcons from '../pages/sideIcons'

const Layout = props => {
  const { children } = props

  return (
    <>
      <Navbar />
      <StickyIcons />
      {children}
      <Footer />
    </>
  )
}

export default Layout
