import React, { useState } from 'react';

// MUI
import { Box, IconButton, Tooltip, Grid } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const StickyIcons = () => {
  const [expanded, setExpanded] = useState(true);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid container direction="column" alignItems="flex-end" style={{ position: 'fixed', top: '50%', transform: 'translateY(-120%)', zIndex: 1000 }}>
      <Grid item>
        <IconButton onClick={toggleExpansion} style={{ background: '#1A2434', cursor: 'pointer', borderRadius: '10px 0px 0px 0px', height: '3.375rem', width: '3.375rem' }}>
          {expanded ? <ArrowForwardIosIcon style={{ fontSize: '20px', color: 'white' }}/> : <ArrowBackIosIcon style={{ fontSize: '20px', color: 'white' }}/>}
        </IconButton>
      </Grid>
      
      {expanded && (
        <Box style={{ height: 0, transition: 'height 0.3s ease-in-out' }}>
          <Grid container direction="column" alignItems="center">
          <Grid>
              <Tooltip title="WhatsApp">
                <IconButton href="https://api.whatsapp.com/send?phone=+1234567890" className="icon whatsapp" style={{ background: '#2BB564', cursor: 'pointer', borderRadius: '0px', height: '3.375rem', width: '3.375rem' }}>
                  <WhatsAppIcon style={{ fontSize: '20px', color: 'white' }}/>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid>
              <Tooltip title="Call">
                <IconButton href="tel:+1234567890" className="icon phone" style={{ background: '#3743F1', cursor: 'pointer', borderRadius: '0px 0px 0px 10px', height: '3.375rem', width: '3.375rem' }}>
                  <PhoneIcon style={{ fontSize: '20px', color: 'white' }}/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      )}
    </Grid>
  );
};

export default StickyIcons;
