// Nextjs
import Image from 'next/image'

// MUI
import { styled } from '@mui/material'

export const HeadSectionImg = styled(Image)({
  display: 'block',
  width: '100%',
  height: 'auto',
  aspectRatio: 9 / 16,
  objectFit: 'cover',
})
