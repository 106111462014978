import { Button, styled } from '@mui/material'

export const MeditaggButton = styled(Button)(({ theme }) => ({
  width: 'auto',
  height: theme.typography.pxToRem(54),
  background: 'var(--primary-color)',
  padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(15)}`,
  fontFamily: 'var(--primary-font)',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(17),
  lineHeight: '200%',
  letterSpacing: '0.03em',
  color: '#FFFFFF',
  borderRadius: 0,
  // Button Effect
  boxShadow: 'inset 0 0 0 0 var(--primary-color)',
  transition: 'all ease 1s',

  '&:hover': {
    width: 'auto',
    height: theme.typography.pxToRem(54),
    boxShadow: 'inset 200px 0 0 0 #FFF',
    border: '1px solid var(--primary-color)',
    background: 'transparent',
    color: 'var(--primary-color)',
  },
}))

export const TransparentButton = styled(Button)(({ theme }) => ({
  width: 'auto',
  height: theme.typography.pxToRem(54),
  background: '#FFFFFF',
  padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(15)}`,
  fontFamily: 'var(--primary-font)',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(17),
  lineHeight: '200%',
  letterSpacing: '0.03em',
  color: 'var(--primary-color)',
  borderRadius: 0,
  display: 'grid',
  placeItems: 'center',
  // Button Effect
  boxShadow: 'inset 0 0 0 0 #FFFFFF',
  transition: 'all ease 1s',

  '&:hover': {
    width: 'auto',
    height: theme.typography.pxToRem(54),
    boxShadow: 'inset 200px 0 0 0 var(--primary-color)',
    border: '1px solid #FFF',
    background: 'transparent',
    color: '#FFF',
  },
}))

export const PrimaryButton = styled(Button)(({ theme }) => ({
  width: 'auto',
  height: theme.typography.pxToRem(54),
  background: 'var(--blue-color)',
  padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(15)}`,
  fontFamily: 'var(--primary-font)',
  fontStyle: 'normal',
  fontWeight: 200,
  fontSize: theme.typography.pxToRem(17),
  lineHeight: '200%',
  letterSpacing: '0.03em',
  color: '#FFFFFF',
  borderRadius: 0,
  // Button Effect
  boxShadow: 'inset 0 0 0 0 var(--blue-color)',
  transition: 'all ease 1s',
  borderRadius: '24px',

  '&:hover': {
    width: 'auto',
    height: theme.typography.pxToRem(54),
    boxShadow: 'inset 800px 0 0 0 #FFF',
    border: '1px solid var(--blue-color)',
    background: 'var(--blue-color)',
    color: 'var(--blue-color)',
  },
}))

export const SecondaryButton = styled(Button)(({ theme }) => ({
  width: 'auto',
  height: theme.typography.pxToRem(54),
  background: 'var(--green-color)',
  padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(15)}`,
  fontFamily: 'var(--primary-font)',
  fontStyle: 'normal',
  fontWeight: 200,
  fontSize: theme.typography.pxToRem(17),
  lineHeight: '200%',
  letterSpacing: '0.03em',
  color: '#FFFFFF',
  borderRadius: 0,
  // Button Effect
  boxShadow: 'inset 0 0 0 0 var(--green-color)',
  transition: 'all ease 1s',
  borderRadius: '24px',

  '&:hover': {
    width: 'auto',
    height: theme.typography.pxToRem(54),
    boxShadow: 'inset 800px 0 0 0 #FFF',
    border: '1px solid var(--green-color)',
    background: 'var(--green-color)',
    color: 'var(--green-color)',
  },
}))


