import React from 'react'

// Nextjs
import Image from 'next/image'
import { useRouter } from 'next/router';

// MUI
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  useScrollTrigger,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

// UI
import { ParagraphText, NextLink } from '../../ui'

// Logo from Public Dir
import MeditaggLogo from '../../../public/logo/meditagg_logo.svg'

// Navbar JSON File
import NavbarLinks from '../../dummy-data/navbar.json'

const DesktopNavbar = props => {
  const router = useRouter();
  const { handleDrawerToggle, ...other } = props

  const { navbarLinks } = NavbarLinks

  const trigger = useScrollTrigger()

  const handleNavigateToAbout = (e) => {
    console.log(e)
    router.replace(`/${e}`);
  };
  return (
    <AppBar
      component="nav"
      sx={{
        boxShadow: 'none',
        backgroundColor: trigger ? '#ffffff' : 'transparent',
        position: trigger ? 'fixed' : 'absolute',
      }}
      {...other}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: {
            md: 'space-between',
            xs: 'space-around',
          },
          height: '74px',
          // gap: {
          //   md: 0,
          //   xs: 6,
          // },
        }}
      >
        <NextLink href="/">
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Image
              src={MeditaggLogo}
              alt="Meditagg"
              style={{ cursor: 'pointer' }}
              priority
            />
          </Box>
        </NextLink>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: 'none' } }}
        >
          <MenuIcon sx={{ color: 'black' }} />
        </IconButton>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            gap: 4,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {navbarLinks.map((link, index) => {
            return (
              <Box key={index}>
                <ParagraphText
                  sx={{
                    color: ' #1A2434',
                    cursor: 'pointer'
                  }}
                  onClick={() => { handleNavigateToAbout(link.linkHref) }}
                >
                  {link.label}
                </ParagraphText>
              </Box>
            )
          })}
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default DesktopNavbar
