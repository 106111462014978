import React, { useEffect } from 'react'

// Nextjs
import Head from 'next/head'
import Router from 'next/router'

// nProgress
import nProgress from 'nprogress'

// AOS
import Aos from 'aos'
import 'aos/dist/aos.css'

// @emotion
import { CacheProvider } from '@emotion/react'

// MUI
import { CssBaseline, ThemeProvider } from '@mui/material'

// Uitls
import { createEmotionCache } from '../utils/create-emotion-cache'

// Theme
import { createTheme } from '../theme'

// Layout
import Layout from '../layout/layout'

// Error Boundary - Client Side Error Handling
import ErrorBoundary from '../components/Error-Boundary'

const clientSideEmotionCache = createEmotionCache()

// next Router + nProgress
Router.events.on('routeChangeStart', nProgress.start)
Router.events.on('routeChangeError', nProgress.done)
Router.events.on('routeChangeComplete', nProgress.done)

const MyApp = props => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  useEffect(() => {
    // here you can add your aos options
    Aos.init({
      duration: 1000,
    })
  }, [])

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Meditagg</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5, shrink-to-fit=no"
        />
      </Head>
      <ErrorBoundary>
        <ThemeProvider
          theme={createTheme({
            direction: 'ltr',
            responsiveFontSizes: true,
            mode: 'light',
          })}
        >
          <CssBaseline />

          <Layout>
            <Component {...pageProps} />
          </Layout>
        </ThemeProvider>
      </ErrorBoundary>
    </CacheProvider>
  )
}

export default MyApp
