import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  styled,
} from '@mui/material'

export const Accordion = styled(MuiAccordion)({
  backgroundColor: 'transparent',
  boxShadow: 'none',

  //   borderBottom: `1px solid #DFDFDF`,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  padding: '5px 0',
})

export const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  margin: 0,
  padding: 0,

  '& .MuiAccordionSummary-content': {
    margin: '10px 0',
    marginLeft: theme.spacing(1),
  },
}))

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    textIndent: 0,
    padding: 0,
    margin: 0,
  },
}))
