import React, { useState } from 'react'

// MUI
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

// UI
import { ParagraphText, NextLink } from '../../ui'

// Navbar JSON File
import NavbarLinks from '../../dummy-data/navbar.json'

// Sidebar Drawer Width
const drawerWidth = 260

const MobileNavbar = props => {
  const { handleDrawerToggle, mobileOpen, ...other } = props

  const { navbarLinks } = NavbarLinks

  let windo
  const container =
    windo !== undefined ? () => window().document.body : undefined

  const drawer = (
    <Box>
      <List>
        {navbarLinks.map((links, index) => {
          return (
            <Box key={index}>
              <ListItem disablePadding>
                <ListItemButton sx={{ ml: 2 }} onClick={handleDrawerToggle}>
                  <NextLink href={links.linkHref}>
                    <ListItemText
                      primary={<ParagraphText>{links.label}</ParagraphText>}
                    />
                  </NextLink>
                </ListItemButton>
              </ListItem>
            </Box>
          )
        })}
      </List>
    </Box>
  )

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    handleDrawerToggle();
  };

  return (
    <Box component="nav" {...other}>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          zIndex: 99999,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer}
          sx={{ mr: 2 }}
        >
          <CloseIcon />
        </IconButton>
        {drawer}
      </Drawer>
    </Box>
  )
}

export default MobileNavbar
