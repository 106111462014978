import React, { useState } from 'react'

// MUI
import { Box, Toolbar } from '@mui/material'

// Navbar Components
import DesktopNavbar from './navbar/desktop'
import MobileNavbar from './navbar/mobile'

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

  return (
    <Box sx={{ display: 'flex' }}>
      <DesktopNavbar handleDrawerToggle={handleDrawerToggle} />
      <MobileNavbar
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
      />
      <Box component="main" sx={{ p: 1 }}>
        <Toolbar />
      </Box>
    </Box>
  )
}

export default Navbar
